<template>
  <div id="app">
    <ControlPanel v-if="isAdmin"/>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
  const ControlPanel = () => import("@/components/core/ControlPanel");
  export default {
    components: {
      ControlPanel,
    },
    computed: {
      isAdmin() {
        if (this.$store.state.auth.user) {
          return this.$store.state.auth.user.role == "ROOT";
        }
      },
    },
    created() {
      this.$store.dispatch("plans/GET_PLANS");
    },
  };
</script>

<style lang="scss">
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .25s ease-out;
  }
</style>