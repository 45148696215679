import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import { data } from "jquery";

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

// firebase.firestore().doc("/system/THEME").update(
//   {
//     "pages": [
//       {
//         "name": "Home",
//         "children": [
//           {
//             "folder": "core",
//             "name": "Navbar"
//           },
//           {
//             "folder": "home",
//             "name": "Header",
//             "props": {
//               "subtitle": "Get a insurance quote and learn why millions of households trust AIG for their insurance needs.",
//               "title": "Experience, Expertise, Consulting"
//             }
//           },
//           {
//             "name": "BenefitsList",
//             "props": {
//               "items": [
//                 {
//                   "title": "Edad",
//                   "description": "Edad de ingreso a la póliza, para fines de cobertura, no podrá ser menor de tres (3) meses ni mayor de ocho (08) años. Edad límite de permanencia hasta cumplir los doce (12) años."
//                 },
//                 {
//                   "title": "Consulta de Rutina",
//                   "description": "Cubre los gastos usuales y razonables de una consulta médica veterinaria, en las clínicas / hospitales veterinarios o en Prestadores de Servicio afiliados a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza."
//                 },
//                 {
//                   "description": "La Compañía pagará en nombre del asegurado todas las sumas que legalmente este se vea obligado a pagar, en su condición de propietario de la (s) mascota (s) indicada (s) en las condiciones particulares de la póliza, por daños y perjuicios que hayan originado, dentro del período de vigencia de la misma.",
//                   "title": "Responsabilidad"
//                 },
//                 {
//                   "title": "Emergencias",
//                   "description": "Cubre los gastos veterinarios usuales y razonables de emergencia /urgencias hospitalarias, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza."
//                 },
//                 {
//                   "title": "Cirugías",
//                   "description": "Cubre los gastos usuales y razonables de la cirugía incluyendo la anestesia y honorarios veterinarios, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza."
//                 },
//                 {
//                   "description": "Cubre los gastos usuales y razonables de hospitalización, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
//                   "title": "Hospitalización"
//                 },
//                 {
//                   "description": "Cubre los gastos usuales y razonables de los exámenes, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
//                   "title": "Examen de laboratorio e imágenes"
//                 },
//                 {
//                   "description": "Cubre los gastos usuales y razonables de tratamiento odontológico, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados a seguir y en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
//                   "title": "Tratamiento Odontológico"
//                 },
//                 {
//                   "description": "Cubre los honorarios veterinarios usuales y razonables, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados a seguir y en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
//                   "title": "Parto"
//                 }
//               ],
//               "description": "Conoce algunas de las razones por las que debes elegirnos",
//               "title": "Beneficios del Plan"
//             },
//             "folder": "benefits"
//           },
//           {
//             "folder": "shared",
//             "name": "SectionWithCallToAction",
//             "props": {
//               "description": "PetDr. Plan asegura a tu mascota ante cualquier eventualidad, por enfermedad o accidente según los términos y condiciones del plan seleccionado. Brindándote a ti y a tu mascota la seguridad y tranquilidad que necesitas.",
//               "btnTxt": "Solicitar",
//               "title": "Protege a tus mascotas con nosotros",
//               "boxColor": "bg-warning"
//             }
//           },
//           {
//             "name": "Plans",
//             "folder": "home"
//           },
//           {
//             "props": {
//               "items": [
//                 {
//                   "date": {
//                     "nanoseconds": 0,
//                     "seconds": 1565672400
//                   },
//                   "imageURL": "https://firebasestorage.googleapis.com/v0/b/petdr-do.appspot.com/o/Por-que%CC%81-los-gatos-ronronean-1024x438.jpg?alt=media&token=4b7f3d6a-9e7f-44af-a509-e2c338b209a1",
//                   "imgHover": "Calvin Carlo",
//                   "content": "¿Por qué los gatos ronronean?"
//                 },
//                 {
//                   "imageURL": "https://firebasestorage.googleapis.com/v0/b/petdr-do.appspot.com/o/gatos-o-perros-1024x438.jpg?alt=media&token=597fac09-93c0-45d0-88d7-ffefd95bdc70",
//                   "date": {
//                     "seconds": 1565672400,
//                     "nanoseconds": 0
//                   },
//                   "content": "Debemos cepillar los dientes de nuestros gatos",
//                   "imgHover": "Calvin Carlo"
//                 },
//                 {
//                   "imgHover": "Calvin Carlo",
//                   "date": {
//                     "nanoseconds": 0,
//                     "seconds": 1565672400
//                   },
//                   "content": "Las mascotas son un apoyo incondicional",
//                   "imageURL": "https://firebasestorage.googleapis.com/v0/b/petdr-do.appspot.com/o/grupos-sangui%CC%81neos.jpg?alt=media&token=66423f04-826b-4a5d-952e-aab0a8db7258"
//                 }
//               ],
//               "title": "PetDr. Plan Blog",
//               "Description": "Compartimos artículos, tips, consejos y más para los amantes de las mascotas"
//             },
//             "folder": "blog",
//             "name": "BlogWidget"
//           },
//           {
//             "folder": "core",
//             "name": "Footer"
//           }
//         ]
//       },
//       {
//         "name": "Login",
//         "children": [
//           {
//             "folder": "login",
//             "name": "Form"
//           }
//         ]
//       },
//       {
//         "name": "Development",
//         "children": [
//           {
//             "props": {
//               "title": "Beneficios del Plan",
//               "items": [
//                 {
//                   "description": "Edad de ingreso a la póliza, para fines de cobertura, no podrá ser menor de tres (3) meses ni mayor de ocho (08) años. Edad límite de permanencia hasta cumplir los doce (12) años.",
//                   "title": "Edad"
//                 },
//                 {
//                   "description": "Cubre los gastos usuales y razonables de una consulta médica veterinaria, en las clínicas / hospitales veterinarios o en Prestadores de Servicio afiliados a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
//                   "title": "Consulta de Rutina"
//                 },
//                 {
//                   "description": "La Compañía pagará en nombre del asegurado todas las sumas que legalmente este se vea obligado a pagar, en su condición de propietario de la (s) mascota (s) indicada (s) en las condiciones particulares de la póliza, por daños y perjuicios que hayan originado, dentro del período de vigencia de la misma.",
//                   "title": "Responsabilidad Civil"
//                 },
//                 {
//                   "title": "Emergencias",
//                   "description": "Cubre los gastos veterinarios usuales y razonables de emergencia /urgencias hospitalarias, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza."
//                 },
//                 {
//                   "description": "Cubre los gastos usuales y razonables de la cirugía incluyendo la anestesia y honorarios veterinarios, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
//                   "title": "Cirugías"
//                 },
//                 {
//                   "description": "Cubre los gastos usuales y razonables de hospitalización, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
//                   "title": "Hospitalización"
//                 },
//                 {
//                   "title": "Examen de laboratorio e imágenes",
//                   "description": "Cubre los gastos usuales y razonables de los exámenes, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza."
//                 },
//                 {
//                   "title": "Tratamiento Odontológico",
//                   "description": "Cubre los gastos usuales y razonables de tratamiento odontológico, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados a seguir y en las Condiciones Particulares o Certificado de Seguro de esta póliza."
//                 },
//                 {
//                   "title": "Parto",
//                   "description": "Cubre los honorarios veterinarios usuales y razonables, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados a seguir y en las Condiciones Particulares o Certificado de Seguro de esta póliza."
//                 }
//               ],
//               "description": "Conoce algunas de las razones por las que debes elegirnos"
//             },
//             "name": "BenefitsList",
//             "folder": "benefits"
//           },
//           {
//             "props": {
//               "btnTxt": "Solicitar",
//               "boxColor": "bg-warning",
//               "title": "Protege a tus mascotas con nosotros",
//               "description": "PetDr. Plan asegura a tu mascota ante cualquier eventualidad, por enfermedad o accidente según los términos y condiciones del plan seleccionado. Brindándote a ti y a tu mascota la seguridad y tranquilidad que necesitas."
//             },
//             "name": "SectionWithCallToAction",
//             "folder": "shared"
//           },
//           {
//             "name": "Plans",
//             "folder": "home"
//           },
//           {
//             "name": "BlogWidget",
//             "folder": "blog",
//             "props": {
//               "title": "PetDr. Plan Blog",
//               "Description": "Compartimos artículos, tips, consejos y más para los amantes de las mascotas",
//               "items": [
//                 {
//                   "content": "¿Por qué los gatos ronronean?",
//                   "date": {
//                     "nanoseconds": 0,
//                     "seconds": 1565672400
//                   },
//                   "imageURL": "https://via.placeholder.com/300x200",
//                   "imgHover": "Calvin Carlo"
//                 },
//                 {
//                   "imageURL": "https://via.placeholder.com/300x200",
//                   "content": "Debemos cepillar los dientes de nuestros gatos",
//                   "date": {
//                     "seconds": 1565672400,
//                     "nanoseconds": 0
//                   },
//                   "imgHover": "Calvin Carlo"
//                 },
//                 {
//                   "imgHover": "Calvin Carlo",
//                   "date": {
//                     "nanoseconds": 0,
//                     "seconds": 1565672400
//                   },
//                   "imageURL": "https://via.placeholder.com/300x200",
//                   "content": "Las mascotas son un apoyo incondicional"
//                 }
//               ]
//             }
//           },
//           {
//             "folder": "shared",
//             "name": "SectionWithBottomImage",
//             "props": {
//               "description": "es el Seguro para Mascotas que usted necesita con el respaldo de Seguros Sura, S.A. Contamos con servicio al cliente a nivel nacional las 24 horas del día a través de una Red de Clínicas Veterinarias Referidas especialmente seleccionada para el bienestar de su mascota.",
//               "title": "Sobre nosotros",
//               "items": {
//                 "imageURL": "https://via.placeholder.com/480x240"
//               }
//             }
//           },
//           {
//             "name": "SectionWithImageAndItems",
//             "folder": "shared",
//             "props": {
//               "items": {
//                 "sections": [
//                   {
//                     "title": "Use On Any Device",
//                     "description": "Compo in a pseudo-Latin language which more or less pseudo-Latin language corresponds."
//                   },
//                   {
//                     "title": "use on any device 2",
//                     "description": "Compo in a pseudo-Latin language which more or less pseudo-Latin language corresponds."
//                   },
//                   {
//                     "description": "Compo in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
//                     "title": "use on any device 3"
//                   },
//                   {
//                     "title": "use on any device 4",
//                     "description": "Compo in a pseudo-Latin language which more or less pseudo-Latin language corresponds."
//                   }
//                 ],
//                 "imageURL": "https://via.placeholder.com/360x360"
//               },
//               "title": "Misión, Visión y Valores",
//               "description": "Más que una compañía de seguros, somo somos una familia. Nuestros valores son nuestra carta de presentación."
//             }
//           },
//           {
//             "props": {
//               "description": "¿Tienes dudas? ¿Primera vez adquiriendo un seguro para tu mascota? A continuación, resolvemos las dudas más frecuentes de nuestros clientes.",
//               "items": [
//                 {
//                   "title": "How our Landrick work ?",
//                   "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
//                 },
//                 {
//                   "title": "How our Landrick work ?",
//                   "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
//                 }
//               ],
//               "title": "Preguntas frecuentes"
//             },
//             "folder": "shared",
//             "name": "SectionWithAccordionList"
//           },
//           {
//             "folder": "shared",
//             "props": {
//               "exclusions": [
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 1",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 2",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 3",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 4",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde.5",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 6",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 7",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 8",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 9",
//                 "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur beatae quidem aspernatur dolor unde. 10"
//               ],
//               "description": "Entérate del las condiciones en la que entra en excepción la póliza de seguro.",
//               "title": "Principales Exclusiones"
//             },
//             "name": "SectionWithSimpleList"
//           },
//           {
//             "name": "DemoComponent",
//             "folder": "shared",
//             "props": {
//               "title": "Este es mi título",
//               "titleColor": "#ffde00",
//               "items": [
//                 {
//                   "content": "Este contenido no vale cotopl4",
//                   "imageURL": "https://via.placeholder.com/60x60"
//                 },
//                 {
//                   "imageURL": "https://via.placeholder.com/60x60",
//                   "content": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime, quos voluptate. Perspiciatis eaque optio necessitatibus ipsam ullam corrupti cupiditate animi ea, vitae ut molestiae aliquid iusto a, aperiam commodi tempore?"
//                 },
//                 {
//                   "content": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime, quos voluptate. Perspiciatis eaque optio necessitatibus ipsam ullam corrupti cupiditate animi ea, vitae ut molestiae aliquid iusto a, aperiam commodi tempore?",
//                   "imageURL": "https://via.placeholder.com/60x60"
//                 }
//               ]
//             }
//           },
//         ]
//       },
//       {
//         "name": "Faqs",
//         "children": [
//           {
//             "name": "Navbar",
//             "folder": "core"
//           },
//           {
//             "props": {
//               "description": "¿Tienes dudas? ¿Primera vez adquiriendo un seguro para tu mascota? A continuación, resolvemos las dudas más frecuentes de nuestros clientes.",
//               "title": "Preguntas frecuentes",
//               "items": [
//                 {
//                   "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
//                   "title": "How our Landrick work ?"
//                 },
//                 {
//                   "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
//                   "title": "How our Landrick work ?"
//                 },
//                 {
//                   "title": "How our Landrick work ?",
//                   "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
//                 },
//                 {
//                   "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
//                   "title": "How our Landrick work ?"
//                 }
//               ]
//             },
//             "folder": "shared",
//             "name": "SectionWithAccordionList"
//           },
//           {
//             "name": "Footer",
//             "folder": "core"
//           }
//         ]
//       },
//       {
//         "name": "Somos",
//         "children": [
//           {
//             "folder": "core",
//             "name": "Navbar"
//           },
//           {
//             "folder": "home",
//             "name": "Header",
//             "props": {
//               "subtitle": "Get a insurance quote and learn why millions of households trust AIG for their insurance needs.",
//               "title": "Experience, Expertise, Consulting"
//             }
//           },
//           {
//             "props": {
//               "title": "Sobre nosotros",
//               "items": {
//                 "imageURL": "https://firebasestorage.googleapis.com/v0/b/petdr-do.appspot.com/o/logo_sura-300x101.png?alt=media&token=521694bb-ecd2-42af-9446-9cef89e566bf"
//               },
//               "description": "es el Seguro para Mascotas que usted necesita con el respaldo de Seguros Sura, S.A. Contamos con servicio al cliente a nivel nacional las 24 horas del día a través de una Red de Clínicas Veterinarias Referidas especialmente seleccionada para el bienestar de su mascota."
//             },
//             "name": "SectionWithBottomImage",
//             "folder": "shared"
//           },
//           {
//             "props": {
//               "description": "Más que una compañía de seguros, somo somos una familia. Nuestros valores son nuestra carta de presentación.",
//               "title": "Misión, Visión y Valores",
//               "items": {
//                 "sections": [
//                   {
//                     "title": "Use On Any Device",
//                     "description": "Compo in a pseudo-Latin language which more or less pseudo-Latin language corresponds."
//                   },
//                   {
//                     "description": "Compo in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
//                     "title": "use on any device 2"
//                   },
//                   {
//                     "description": "Compo in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
//                     "title": "use on any device 3"
//                   },
//                 ],
//                 "imageURL": "https://firebasestorage.googleapis.com/v0/b/petdr-do.appspot.com/o/shutterstock_125558591.jpg?alt=media&token=a7b4b819-ebc0-4ac6-b03e-bf39b251ed5d"
//               }
//             },
//             "folder": "shared",
//             "name": "SectionWithImageAndItems"
//           },
//           {
//             "folder": "shared",
//             "name": "SectionWithCallToAction",
//             "props": {
//               "description": "PetDr. Plan asegura a tu mascota ante cualquier eventualidad, por enfermedad o accidente según los términos y condiciones del plan seleccionado. Brindándote a ti y a tu mascota la seguridad y tranquilidad que necesitas.",
//               "btnTxt": "Solicitar",
//               "title": "Protege a tus mascotas con nosotros",
//               "boxColor": "bg-warning"
//             }
//           },
//           {
//             "folder": "core",
//             "name": "Footer"
//           }
//         ]
//       },
//       {
//         "name": "Exclusiones",
//         "children": [
//           {
//             "folder": "core",
//             "name": "Navbar"
//           },
//           {
//             "folder": "shared",
//             "props": {
//               "exclusions": [
//                 "Responsabilidad Civil por: perjuicios al Asegurado, cónyuge, familiares con parentesco hasta el 4to. Grado de consanguinidad o 2do. de afinidad; daños morales; por eventos catastróficos de la naturaleza, guerra, motín, huelga, terrorismo y similares; multas, perjuicios por hechos ocurridos fuera del territorio de la República Dominicana.",
//                 "Gastos veterinarios por enfermedad de la mascota si su edad es menor de 3 meses o mayor de 12 años.",
//                 "Reclamaciones presentadas fuera del Plazo de Vigencia de la póliza.",
//                 "Reclamaciones cuyo hecho ocurriera previo al inicio de vigencia de la póliza, conocido o no por parte del Asegurado.",
//                 "Reclamaciones cuyo hecho ocurre dentro del Periodo de Carencia.",
//                 "Gastos por enfermedades que se determine que son enfermedades preexistentes o congénitas.",
//                 "No quedan cubiertos los medicamentos recetados, excepto aquellos que hayan sido suministrados hospitalariamente.",
//                 "La inobservancia de las recomendaciones del veterinario como necesarias para el tratamiento del accidente",
//                 "No se consideran accidentes los abortos o partos que ocurran 48 horas después del accidente.9",
//                 "Gastos por enfermedades infecciosas si la mascota no tiene su carnet de vacunación al día."
//               ],
//               "description": "Entérate del las condiciones en la que entra en excepción la póliza de seguro.",
//               "title": "Principales Exclusiones"
//             },
//             "name": "SectionWithSimpleList"
//           },
//           {
//             "name": "Footer",
//             "folder": "core"
//           },
//         ]
//       },
//     ],
//   }
// )

export default firebase;

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();