import { auth, firestore, } from "@/services/firebase.service";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    SET_USER: (state, payload) => state.user = payload,
  },
  actions: {
    SIGN_IN: ({ commit, }, uid) => new Promise((resolve, reject) => {

      let user;

      firestore.doc("/users/" + uid)
        .get()
        .then(snapshot => snapshot.data())
        .then(profile => {
          user = profile;
          return auth.currentUser.getIdTokenResult();
        })
        .then(({ claims, }) => {
          const { role, app, } = claims;
          commit("SET_USER", { ...user, ...{ role, app, }, });
          resolve();
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    }),
    SIGN_OUT: ({ commit, }) => new Promise((resolve, reject) => {
      auth.signOut()
        .then(() => {
          commit("SET_USER", null);
          resolve();
        })
        .catch(e => {
          console.error(e);
          reject(e);
        })
    }),
  },
};