import AIG from "./AIG";
import PETDR from "./PETDR";

const routers = {
  AIG,
  PETDR
};

export default {
  get: name => routers[name],
};
